import {
  Button,
  Form,
  InputGroup,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { BsCheckAll } from "react-icons/bs";
import { IoIosWarning } from "react-icons/io";
import { useQuery } from "@tanstack/react-query";
import useDebounce from "../hooks/useDebounce";

export default function SheetInput({
  sheet,
  onSheetLinkChange,
  onSheetNameChange,
  index,
}) {
  // debounce the sheet id and name to avoid unnecessary requests
  const debouncedSheetLink = useDebounce(sheet.link, 800);
  const debouncedSheetName = useDebounce(sheet.spreadSheetName, 800);
  const checkAccess = async () => {
    const response = await fetch(
      `spreadsheets/check-access?spreadsheetLink=${encodeURIComponent(
        debouncedSheetLink
      )}&spreadsheetName=${debouncedSheetName}`
    );
    if (response.ok) {
      return response.json();
    }
    console.error("An error occurred");
    return false;
  };

  const {
    data: isAccessible,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: [
      "sheets",
      "check-access",
      debouncedSheetLink,
      debouncedSheetName,
    ],
    queryFn: checkAccess,
    refetchOnWindowFocus: false,
  });
  const isChecking = isLoading || isFetching;
  return (
    <InputGroup className="mb-3">
      <Form.Control
        disabled={sheet.status === "Stopped"}
        placeholder="Enter Sheet Link"
        value={sheet?.link || ""}
        onChange={onSheetLinkChange}
      />
      <Form.Control
        disabled={sheet.status === "Stopped"}
        placeholder="Enter Sheet Name"
        value={sheet?.spreadSheetName || ""}
        onChange={onSheetNameChange}
      />

      {isChecking && (
        <Button variant="outline-light" disabled>
          <Spinner
            as="span"
            variant="primary"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        </Button>
      )}
      {!isChecking && isAccessible && (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip id={`tooltip-${index}`}>Sheet is accessible</Tooltip>
          }
        >
          <div>
            <Button title="test" variant="link" disabled>
              <BsCheckAll
                style={{
                  fill: "green",
                  width: "20px",
                  height: "20px",
                }}
              />
            </Button>
          </div>
        </OverlayTrigger>
      )}
      {!isChecking && !isAccessible && (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip id={`tooltip-${index}`}>Sheet is not accessible</Tooltip>
          }
          message="Cannot access the sheet"
        >
          <div>
            <Button title="test" variant="link" disabled>
              <IoIosWarning
                style={{
                  fill: "red",
                  width: "20px",
                  height: "20px",
                }}
              />
            </Button>
          </div>
        </OverlayTrigger>
      )}
    </InputGroup>
  );
}
