import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { SheetType } from "../types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

export function ConcatenateSheetRow({ sheetMeta }) {
  const queryClient = useQueryClient();

  function handleConcatenate(sheetName) {
    const concatenatePromise = fetch(
      `spreadsheets/concatenate-sheets/${sheetName}`,
      {
        method: "POST",
      }
    ).then((response) => {
      if (!response.ok) {
        throw new Error("An error occurred");
      }
      return Promise.resolve();
    });

    toast.promise(concatenatePromise, {
      pending: "Concatenating...",
      success: "Successfully 🎉",
      error: "An error occurred 🤯",
    });

    return concatenatePromise;
  }
  const concatenateMutation = useMutation({
    mutationFn: handleConcatenate,
    onSettled: () => queryClient.invalidateQueries(["sheets", "concat"]),
  });

  return (
    <tr>
      <td>
        <Link to={`/${SheetType.concat}/${sheetMeta.sheetName}`}>
          {sheetMeta.sheetName}
        </Link>
      </td>
      {/* <td>{row.count}</td> */}
      <td>
        {sheetMeta?.updatedAt
          ? new Intl.DateTimeFormat("vi-VN", {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
              hour12: false,
              timeZone: "Asia/Ho_Chi_Minh",
            }).format(new Date(sheetMeta?.updatedAt))
          : null}
      </td>
      <td>
        <Button
          disabled={concatenateMutation.isPending}
          variant="primary"
          onClick={() => concatenateMutation.mutate(sheetMeta.sheetName)}
        >
          {concatenateMutation.isPending ? "Concatenating..." : "Concatenate"}
        </Button>
      </td>
    </tr>
  );
}
