import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import SourceSheets from "../components/SourceSheets";
import ResultSheet from "../components/ResultSheet";
import { DataSheetType } from "../types";

export default function AddSheets() {
  const { sheetName } = useParams();

  const { data: sheets, isLoading: queryLoading } = useQuery({
    queryKey: ["sheets", "all", sheetName],
    queryFn: () =>
      fetch(`spreadsheets/all/${sheetName}`).then((response) =>
        response.json()
      ),
  });
  const sourceSheets = sheets?.filter((x) => x.type === DataSheetType.Source);

  const resultSheet = sheets?.find((x) => x.type === DataSheetType.Result);

  if (queryLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Container fluid className={"pb-5"}>
        <Row>
          <Col>
            <h1>Add Sheets ({sheetName})</h1>
          </Col>
        </Row>

        {/* key is to force re-render when route change */}
        <SourceSheets
          key={`${sheetName}-source`}
          sheets={sourceSheets}
        ></SourceSheets>

        <ResultSheet
          key={`${sheetName}-result`}
          sheet={resultSheet}
        ></ResultSheet>
      </Container>
    </>
  );
}
