import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

import { Button, Col, Row } from "react-bootstrap";
import SheetInput from "./SheetInput";

export default function SourceSheets({ sheets }) {
  const [sourceSheets, setSourceSheets] = useState(
    !sheets || sheets.length === 0
      ? [{ type: "Source", link: "", spreadSheetName: "" }]
      : sheets
  );
  const { sheetName } = useParams();
  const queryClient = useQueryClient();

  const handleAddMoreSheet = () => {
    const newSheets = [...sourceSheets, { type: "Source" }];
    setSourceSheets(newSheets);
  };

  function handleRemoveSheet(index) {
    const newSheets = sourceSheets.filter((_, i) => i !== index);
    setSourceSheets(newSheets);
  }

  const handleSubmitSourceSheets = (sheets) => {
    const nonEmptySheets = sheets.filter(
      (sheet) => !!sheet.link && !!sheet.spreadSheetName
    );

    const submitPromise = fetch(`spreadsheets/add-sheets/${sheetName}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(nonEmptySheets),
    }).then((response) => {
      if (!response.ok) {
        throw new Error("An error occurred");
      }
    });

    toast.promise(submitPromise, {
      pending: "Adding sheets...",
      success: "Sheets added successfully 🎉",
      error: "An error occurred 🤯",
    });

    return submitPromise;
  };

  const submitSourceSheetMutation = useMutation({
    mutationFn: handleSubmitSourceSheets,
    onSettled: (data, error, variables, context) => {
      queryClient.invalidateQueries(["sheets", "all", sheetName]);
    },
  });

  return (
    <>
      {sourceSheets?.map((sheet, index) => (
        <Row key={index}>
          <Col>
            <SheetInput
              sheet={sheet}
              index={index}
              onSheetLinkChange={(e) => {
                sheet.link = e.target.value;
                setSourceSheets([...sourceSheets]);
              }}
              onSheetNameChange={(e) => {
                sheet.spreadSheetName = e.target.value;
                setSourceSheets([...sourceSheets]);
              }}
            />
          </Col>
          <Col
            className="d-flex align-items-start"
            style={{ gap: "8px" }}
            md={2}
          >
            {sourceSheets.length > 1 && (
              <Button
                variant="outline-danger"
                onClick={() => handleRemoveSheet(index)}
              >
                -
              </Button>
            )}
            {index === sourceSheets.length - 1 && (
              <Button variant="outline-primary" onClick={handleAddMoreSheet}>
                +
              </Button>
            )}
          </Col>
        </Row>
      ))}

      <Button
        disabled={submitSourceSheetMutation.isPending}
        variant="success"
        onClick={() => submitSourceSheetMutation.mutate(sourceSheets)}
      >
        {submitSourceSheetMutation.isPending ? "Loading…" : "Submit"}
      </Button>
    </>
  );
}
