import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

import { Button, Col, Row } from "react-bootstrap";
import SheetInput from "./SheetInput";

export default function ResultSheet({ sheet }) {
  const [resultSheet, setResultSheet] = useState(
    !sheet ? { type: "Source", link: "", spreadSheetName: "" } : sheet
  );
  const { sheetName } = useParams();
  const queryClient = useQueryClient();

  const handleSubmitResultSheet = () => {
    const addResultSheetPromise = fetch(
      `spreadsheets/add-result-sheet/${sheetName}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(resultSheet),
      }
    ).then((response) => {
      if (!response.ok) {
        throw new Error("An error occurred");
      }

      return Promise.resolve();
    });

    toast.promise(addResultSheetPromise, {
      pending: "Adding result sheet...",
      success: "Result sheet added successfully 🎉",
      error: "An error occurred 🤯",
    });

    return addResultSheetPromise;
  };

  const submitResultSheetMutation = useMutation({
    mutationFn: handleSubmitResultSheet,
    onSettled: () => {
      queryClient.invalidateQueries(["sheets", "all", sheetName]);
    },
  });

  return (
    <>
      <Row>
        <Col>
          <h1>Change Result Sheet</h1>
          <SheetInput
            sheet={resultSheet}
            onSheetLinkChange={(e) => {
              setResultSheet({ ...resultSheet, link: e.target.value });
            }}
            onSheetNameChange={(e) => {
              setResultSheet({
                ...resultSheet,
                spreadSheetName: e.target.value,
              });
            }}
            index={"result"}
          />
        </Col>
      </Row>
      <Button
        disabled={submitResultSheetMutation.isPending}
        variant="success"
        onClick={() => submitResultSheetMutation.mutate()}
      >
        {submitResultSheetMutation.isPending ? "Loading…" : "Submit"}
      </Button>
    </>
  );
}
