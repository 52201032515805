export const AccessibilityStatus = {
  checking: "Checking",
  valid: "Valid",
  invalid: "Invalid",
};

export const SheetType = {
  compute: "compute-sheets",
  concat: "concatenate-sheets",
};

export const DataSheetType = {
  Source: "Source",
  Result: "Result",
};
