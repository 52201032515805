import React from "react";
import SheetTable from "../components/SheetTable";
import { useMetaData } from "../hooks/useMetaData";

const Home = () => {
  const { data } = useMetaData();
  if (!data) return null;

  return (
    <>
      <SheetTable sheetName={data.computeSheetName}></SheetTable>
    </>
  );
};

export default Home;
