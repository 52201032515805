import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Modal, Form } from "react-bootstrap";

export default function CreateNewDbSheetModal({ showModal, handleCloseModal }) {
  const [sheetName, setSheetName] = useState("");
  const handleSheetNameChange = (e) => setSheetName(e.target.value);

  const handleSubmitNewSheet = () => {
    fetch("/spreadsheets/create-concat-sheet", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ sheetName }),
    }).then((response) => {
      if (!response.ok) {
        throw new Error("An error occurred");
      }
    });
  };

  const queryClient = useQueryClient();
  const submitMutation = useMutation({
    mutationFn: handleSubmitNewSheet,
    onSettled: () => {
      queryClient.invalidateQueries(["sheets", "concat"]);
      handleCloseModal();
    },
  });

  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Create New Sheet</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formSheetName">
            <Form.Label>Sheet Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter sheet name"
              value={sheetName}
              onChange={handleSheetNameChange}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Close
        </Button>
        <Button
          disabled={submitMutation.isPending}
          variant="primary"
          onClick={() => submitMutation.mutate()}
        >
          {submitMutation.isPending ? "Creating..." : "Create"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
