import React, { Component } from "react";
import { Container } from "reactstrap";
import NavMenu from "../components/NavMenu";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return (
      <div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          // transition: Bounce
        />
        <ToastContainer />
        <NavMenu />
        <Container tag="main">{this.props.children}</Container>
      </div>
    );
  }
}
