import Home from "./pages/Home";
import AddSheets from "./pages/AddSheets";
import ConcatenateSheets from "./pages/ConcatenateSheets";
import { SheetType } from "./types";

const AppRoutes = [
  {
    index: true,
    element: <Home />,
  },
  {
    element: <ConcatenateSheets></ConcatenateSheets>,
    path: `${SheetType.concat}`,
  },
  {
    element: <AddSheets />,
    path: "/:sheetType/:sheetName",
  },
];

export default AppRoutes;
