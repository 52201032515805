import { Button, Table, Badge } from "react-bootstrap";
import React from "react";
import { toast } from "react-toastify";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { DataSheetType } from "../types";

export default function SheetTable({ sheetName }) {
  const { data: sheets, isLoading: getSheetsLoading } = useQuery({
    queryKey: ["sheets", "all", sheetName],
    queryFn: () =>
      fetch(`spreadsheets/all/${sheetName}`).then((response) =>
        response.json()
      ),
    enabled: !!sheetName,
  });

  const sourceSheets = sheets?.filter((x) => x.type === DataSheetType.Source);
  const resultSheet = sheets?.filter((x) => x.type === DataSheetType.Result)[0];

  const queryClient = useQueryClient();
  const handleSyncAll = () => {
    const syncAllPromise = fetch("spreadsheets/sync-all", {
      method: "POST",
    }).then((response) => {
      if (!response.ok) {
        throw new Error("An error occurred");
      }

      return Promise.resolve();
    });

    toast.promise(syncAllPromise, {
      pending: "Syncing...",
      success: "Sync successfully 🎉",
      error: "An error occurred 🤯",
    });

    return syncAllPromise;
  };

  const syncAllMutation = useMutation({
    mutationFn: handleSyncAll,
    onSettled: () => {
      queryClient.invalidateQueries(["sheets", "all", sheetName]);
    },
  });

  const handleStopSync = (spreadSheetId, spreadSheetName) => {
    const stopSyncPromise = fetch(
      `spreadsheets/stop-sync/?spreadsheetId=${spreadSheetId}&sheetName=${spreadSheetName}`,
      {
        method: "POST",
      }
    ).then((response) => {
      if (!response.ok) {
        throw new Error("An error occurred");
      }

      return Promise.resolve();
    });

    toast.promise(stopSyncPromise, {
      pending: "Stopping...",
      success: "Sync stopped successfully 🎉",
      error: "An error occurred 🤯",
    });

    return stopSyncPromise;
  };

  const stopSyncMutation = useMutation({
    mutationFn: handleStopSync,
    onSettled: () => syncAllMutation.mutate(),
  });

  const isLoading =
    getSheetsLoading || syncAllMutation.isPending || stopSyncMutation.isPending;

  return (
    <>
      <Button
        variant="primary"
        disabled={isLoading}
        onClick={() => syncAllMutation.mutate()}
      >
        {isLoading ? "Loading…" : "Sync Sheets"}
      </Button>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Link</th>
            <th>Sheet Name</th>
            <th>Status</th>
            <th>Last Calculated At</th>
          </tr>
        </thead>
        <tbody>
          {sourceSheets?.map((item, index) => (
            <tr key={`${item.spreadSheetId}_${item.spreadSheetName}`}>
              <td>
                <a href={item.link}>{item.spreadSheetId}</a>
              </td>
              <td>{item.spreadSheetName}</td>
              <td>
                <Badge bg={item.status === "Active" ? "success" : "secondary"}>
                  {item.status}
                </Badge>
              </td>
              <td>
                {resultSheet?.calculatedAt
                  ? new Intl.DateTimeFormat("vi-VN", {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric",
                      hour12: false,
                      timeZone: "Asia/Ho_Chi_Minh",
                    }).format(new Date(resultSheet.calculatedAt))
                  : ""}
              </td>
              {item.status === "Active" ? (
                <td>
                  <Button
                    disabled={isLoading}
                    onClick={() => {
                      stopSyncMutation.mutate(
                        item.spreadSheetId,
                        item.spreadSheetName
                      );
                    }}
                    variant="danger"
                  >
                    {isLoading ? "Loading…" : "Stop Sync"}
                  </Button>
                </td>
              ) : null}
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}
