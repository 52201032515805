import { useState } from "react";
import React from "react";
import { Button, Table } from "react-bootstrap";
import CreateNewDbSheetModal from "../components/CreateNewDbSheetModal";
import { useQuery } from "@tanstack/react-query";
import { ConcatenateSheetRow } from "../components/ConcatenateSheetRow";

export default function ConcatenateSheets() {
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const { data: concatSheets } = useQuery({
    queryKey: ["sheets", "concat"],
    queryFn: () =>
      fetch("/spreadsheets/concat-sheets").then((res) => {
        if (res.ok) {
          return res.json();
        }

        throw new Error("An error occurred");
      }),
  });

  if (!concatSheets) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Button onClick={handleShowModal} variant="success" className="mb-3">
        Create New
      </Button>
      <h1>Concatenate Sheets</h1>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Sheet Name</th>
            {/* <th>Count</th> */}
            <th>Updated At</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {concatSheets.map((row, index) => (
            <ConcatenateSheetRow key={index} sheetMeta={row} />
          ))}
        </tbody>
      </Table>

      <CreateNewDbSheetModal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
      ></CreateNewDbSheetModal>
    </div>
  );
}
